<template>
  <div v-if="isLoading">
    <CCard>
      <CCardBody>
        <h2>
          Loading... Please wait...
          <CSpinner
            color="primary"
            style="
              width: 2rem;
              height: 2rem;
              border: 0.125em solid currentColor;
              border-right-color: transparent;
              margin-left: 10px;
            "
          />
        </h2>
      </CCardBody>
    </CCard>
  </div>
  <div v-else-if="hasLoadingError">
    <CCard>
      <CCardHeader>
        <h2>Error</h2>
      </CCardHeader>
      <CCardBody>
        An Error occurred while trying to load this workflow definition. Please
        see your system administrator to resolve the issue.
        <p class="pt-3">
          <strong>{{ loadingError }}</strong>
        </p>
      </CCardBody>
    </CCard>
  </div>
  <div v-else-if="!isDataFetched">
    <CCard>
      <CCardHeader>
        <h2>Workflow Definition Not Found</h2>
      </CCardHeader>
      <CCardBody>This workflow definition could not be loaded.</CCardBody>
    </CCard>
  </div>
  <CCard v-else>
    <CCardHeader>
      <CRow>
        <CCol>
          <h2>
            Workflow Definition: {{ workflowDef.title || workflowDef.name }}
          </h2>
        </CCol>
        <CCol class="text-right"
          >{{ workflowDef.name }} v{{ workflowDef.version || 1 }}</CCol
        >
      </CRow>
    </CCardHeader>
    <CCardBody>
      <p v-if="workflowDef.description">{{ workflowDef.description }}</p>
      <CDataTable :items="taskDefs" :fields="definitionFields">
        <template #title="{ item }">
          <td>{{ item.taskTitle || item.inputParameters.taskTitle }}</td>
        </template>
        <template #canSkip="{ item }">
          <td>
            <MIcon v-if="item.canSkip" name="fa-check" class="text-success" />
          </td>
        </template>
        <template #userTask="{ item }">
          <td>
            <MIcon v-if="item.userTask" name="fa-check" class="text-success" />
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>
<script>
import { entityLoadingMixin } from '@/mixins/entity-loading-mixin';

const DEF_FIELDS = [
  // TODO: Format task type
  {
    key: 'taskType',
    label: 'Type',
  },
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'taskReferenceName',
    label: 'Task Ref.',
  },
  {
    key: 'userTask',
    label: 'User Task',
  },
  {
    key: 'canSkip',
    label: 'Can Skip',
  },
];

export default {
  name: 'WorkflowDefinition',
  mixins: [entityLoadingMixin],
  props: {
    workflowDefName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dispatchPath: 'workflows/fetchWorkflowDefinition',
    };
  },
  computed: {
    definitionFields() {
      return DEF_FIELDS;
    },
    taskDefs() {
      return this.workflowDef.tasks || [];
    },
    workflowDef() {
      return this.fetchedData || {};
    },
  },
};
</script>
